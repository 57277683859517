<template>
  <div
    class="modal modal__right"
    id="addModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
    v-if="show"
    :class="{ show: show == true }"
    :style="{ display: 'block' }"
  >
    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                class="modal__close modal__close--black"
                @click="closeForm"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              {{ isEditing ? "Edit" : "Create" }} Template
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="row">
                <div class="col-12">
                  <div class="form__item">
                    <label class="form__label">name</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter name"
                      v-model="templateName"
                    />
                  </div>
                </div>
              </div>

              <ul id="accordion">
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#conditions"
                    aria-expanded="false"
                    aria-controls="conditions"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      conditions
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="conditions"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="form__item">
                        <label class="form__label">target</label>
                        <select
                          class="select select--lg"
                          required
                          v-model="templateTarget"
                          @change="selectEvents"
                        >
                          <option value="" disabled selected>
                            Select type
                          </option>
                          <option value="Customer">Customers</option>
                          <option value="Group">Groups</option>
                        </select>
                      </div>
                    </div>

                    <transition name="slide-fade" appear>
                      <div v-if="showEvents">
                        <div class="form__item">
                          <label class="form__label">Event</label>
                          <select
                            class="select select--lg"
                            required
                            v-model="templateEvent"
                          >
                            <option value="" disabled selected>
                              __Select type__
                            </option>
                            <option
                              v-for="event in events"
                              :key="event.notification_event_key" 
                              :value="event.notification_event_name"
                              >{{ event.notification_event_name }}</option
                            >
                          </select>
                        </div>
                      </div>
                    </transition>  

                    <transition name="slide-fade" appear>
                      <div v-if="showEvents">
                        <div class="form__item">
                          <label class="form__label">Notifcation type</label>
                          <select
                            class="select select--lg"
                            required
                            v-model="notificationType"
                          >
                            <option value="" disabled selected>
                              __Select type__
                            </option>
                            <option
                              value="email"
                              >Email</option
                            >
                            <option value="sms">SMS</option>
                          </select>
                        </div>
                      </div>
                    </transition> 

                    <transition name="slide-fade" appear>
                      <div v-if="showEvents">
                        <div
                          class="row align-items-end"
                          v-for="(itemTop, index) in form.filter"
                          :key="index"
                        >
                          <div class="col-3">
                            <div class="form__item">
                              <label
                                class="form__label text--black text--capital mb-3"
                                >{{ index === 0 ? "Where" : "And" }}</label
                              >
                              <select
                                required
                                class="select select--lg text--capital r-font"
                                v-model="itemTop.filterName"
                                @change="changeValue(index)"
                              >
                                <option
                                  value="null"
                                  disabled
                                  selected
                                  v-if="!loading"
                                >
                                  Select Type
                                </option>
                                <option value="null" disabled selected v-else>
                                  Loading...
                                </option>
                                <template v-if="!loading">
                                  <option
                                    v-for="(item, index) in columns"
                                    :key="index"
                                    :value="item.column"
                                  >
                                    {{ item.plainName }}
                                  </option>
                                </template>
                              </select>
                            </div>
                          </div>

                          <div
                            :class="[itemTop.filterOp === 'bt' ? 'col-2 col-pad' : 'col-3 col-pad']"
                            v-if="itemTop.showOp"
                          >
                            <div class="form__item">
                              <select
                                required
                                class="select select--lg text--capital r-font"
                                v-model="itemTop.filterOp"
                              >
                                <template>
                                  <option
                                    v-for="(item, index) in itemTop.filterOpArray"
                                    :key="index"
                                    :value="item.value"
                                  >
                                    {{ item.name }}
                                  </option>
                                </template>
                              </select>
                            </div>
                          </div>

                          <div
                            :class="[itemTop.filterOp === 'bt' ? 'col-3 col-pad' : 'col-4 col-pad']"
                            v-if="itemTop.showOp"
                          >
                            <div class="form__item" v-if="itemTop.dateType === 'System.DateTime'">
                              <input
                                type="date"
                                class="input form__input form__input--lg"
                                placeholder="Enter value"
                                v-model="itemTop.value"
                              />
                            </div>
                            <div class="form__item" v-if="itemTop.dateType === 'System.String'">
                              <input
                                type="text"
                                class="input form__input form__input--lg"
                                placeholder="Enter value"
                                v-model="itemTop.value"
                              />
                            </div>
                            <div class="form__item" v-if="itemTop.dateType === 'System.Enum'">
                              <input
                                type="text"
                                class="input form__input form__input--lg"
                                placeholder="Enter value"
                                v-model="itemTop.value"
                              />
                            </div>
                            <div class="form__item" v-if="itemTop.dateType === 'System.Number'">
                              <input
                                type="number"
                                class="input form__input form__input--lg"
                                placeholder="Enter value"
                                v-model="itemTop.value"
                              />
                            </div>
                          </div>

                          <div 
                            :class="[itemTop.filterOp === 'bt' ? 'col-3 col-pad' : 'col-4 col-pad d-none']" >
                            <div
                              class="form__item"
                              v-if="itemTop.dateType === 'System.DateTime'"
                            >
                              <input
                                type="date"
                                class="input form__input form__input--lg r-font"
                                placeholder="Enter value"
                                v-model="itemTop.value1"
                              />
                            </div>
                          </div>

                          <div class="col-1">
                            <div class="form__item">
                              <button
                                @click="deleteFilter(index)"
                                class="
                                  button
                                  m-1
                                  page__body__header__button
                                  modal__confirm__button--delete
                                  button--sm
                                  text--black
                                  w-100
                                  text--capital
                                "
                              >
                                <span>x</span>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="row ml-3">
                          <button
                            type="button"
                            class="
                              button
                              page__body__header__button
                              button--grey
                              text--capital
                              ml-3
                            "
                            @click="addFilter()"
                          >
                            <span class="d-inline-flex mr-2">add new filter</span>
                            <ion-icon
                              name="add-outline"
                              class="text--medium"
                            ></ion-icon>
                          </button>
                        </div>

                      </div>
                    </transition>                    
                    
                  </div>
                </div>

                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#notes"
                    aria-expanded="false"
                    aria-controls="notes"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Content
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="notes" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div class="form__item">
                        <textarea
                          id=""
                          cols="5"
                          rows="5"
                          class="input form__input"
                          placeholder="Template Body"
                          v-model="templateBody"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>

              <div class="row mt-5">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Placeholder</label>
                    <multiselect
                      v-model="selectedMenu"
                      id="linked"
                      label="plainName"
                      track-by="plainName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="this.custommenus"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="7"
                      :max-height="800"
                      :loading="loading"
                      :preselect-first="false"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.plainName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Placeholder text</label>
                    <span class="input form__input form__input--lg">
                      {{ name }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form__item">
                <div class="form__action">
                  <button
                    data-dismiss="#confirmModal"
                    class="button form__button form__button--lg save-changes"
                    v-if="loading"
                  >
                    <div class="spinner-border texxt-light" role="status"></div>
                  </button>
                  <button
                    v-else
                    type="button"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    @click.prevent="saveTemplate"
                  >
                    save changes
                  </button>
                  <a
                    role="button"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                    @click="closeForm"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";
import Multiselect from "vue-multiselect";
export default {
  name: "createSmsTemplate",
  props: {
    close: Function,
    show: Boolean,
    populatedTemplate: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: () => ({}),
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      form: {
        templateName: "",
        templateBody: "",
        templateTarget: "",
        templateKey: "",
        templateEvent: "",
        notificationType: "",
        filter: [
          {
            filterName: '',
            showOp: false,
            filterOpArray: [],
            filterOp: 'eq',
            filterValue: [],
            value: '',
            value1: '',
            dateType: '',
          },
        ],
      },
      loading: false,
      custommenus: [],
      selectedMenu: null,
      events: [],
      showEvents: false,
      columns: null,
    };
  },
  watch: {
    populatedTemplate(newVal) {
      if (newVal) {
        this.selectEvents();
        return this.getFilters(newVal);
      };
    },
  },
  computed: {
    templateName: {
      get() {
        return !this.isEditingTemplate && this.form.templateName === ""
          ? this.populatedTemplate.templateName
          : this.form.templateName;
      },
      set(newTemplateName) {
        this.form.templateName = newTemplateName;
      },
    },
    templateBody: {
      get() {
        return !this.isEditingTemplate && this.form.templateBody === ""
          ? this.populatedTemplate.templateBody
          : this.form.templateBody;
      },
      set(newTemplateBody) {
        this.form.templateBody = newTemplateBody;
      },
    },
    templateTarget: {
      get() {
        return !this.isEditingTemplate && this.form.templateTarget === ""
          ? this.populatedTemplate.templateTarget
          : this.form.templateTarget;
      },
      set(newTemplateTarget) {
        this.form.templateTarget = newTemplateTarget;
      },
    },
    isEditingTemplate() {
      return Object.keys(this.populatedTemplate).length === 0;
    },
    name: {
      get() {
        return this.selectedMenu ? '{'+this.selectedMenu?.column +'}' : '';
      }
    },
    templateEvent: {
      get() {
        return !this.isEditingTemplate && this.form.templateEvent === ""
          ? this.populatedTemplate.templateEvent
          : this.form.templateEvent;
      },
      set(newTemplateEvent) {
        this.form.templateEvent = newTemplateEvent;
      },
    },
    notificationType: {
      get() {
        return !this.isEditingTemplate && this.form.notificationType === ""
          ? this.populatedTemplate.notificationType
          : this.form.notificationType;
      },
      set(newTemplateType) {
        this.form.notificationType = newTemplateType;
      }
    },
  },
  methods: {
    resetForm() {
      this.form = {
        templateName: "",
        templateBody: "",
        templateTarget: "",
        templateKey: "",
        templateEvent: "",
        notificationType: "",
        filter: [],
      };
      this.showEvents = false;
    },
    addFilter() {
      this.form.filter.push({
        filterName: '',
        showOp: false,
        filterOpArray: [],
        filterOp: '',
        dateType: '',
        filterValue: [],
        value: '',
        value1: '',
      })
    },
    deleteFilter(e) {
      if (this.form.filter.length == 1) {
        this.$emit("error-saving-template", "At least one filter must be present");
        return;
      }
      this.form.filter.splice(e, 1);
    },
    async selectEvents() {
      this.showEvents = true;
      this.columns = [];
      GeneralApiService.get(
        `NotificationEvents/getnotificationeventstargetbytarget/${this.templateTarget}`
      ).then((res) => {
        this.events = res.data.data;
      });
      if (this.templateTarget === 'Customer' ) {
        await GeneralApiService.get('CustomMenu/all-customer-group-custom-menus')
        .then((resp) => {
          this.columns = resp.data.data.customer;
        });
        return;
      }
      await GeneralApiService.get('CustomMenu/all-customer-group-custom-menus')
      .then((resp) => {
        this.columns = resp.data.data.group;
      });
    },
    async getFilters(value) {
      const data = JSON.parse(value.filter);
      const filter = [];
      let columns = [];
      if (this.templateTarget === "Customer" ) {
        await GeneralApiService.get('CustomMenu/all-customer-group-custom-menus')
        .then((resp) => {
          columns = resp.data.data.customer;
        });
      } else {
        await GeneralApiService.get('CustomMenu/all-customer-group-custom-menus')
        .then((resp) => {
          columns = resp.data.data.group;
        });
      }
      data.forEach((item) => {
        const possibleOp = columns.filter((col) => col.column === item.ColumnName);
        filter.push(
          {
            filterName: item.ColumnName,
            showOp: true,
            filterOpArray: possibleOp[0].possibleOperators,
            filterOp: item.Operator,
            filterValue: [],
            value: item.ColumnValue,
            value1: "",
            dateType: item.DataType,
          }
        )
      });
      
      this.form.filter = filter;
    },
    changeValue(index) {
      this.form.filter[index].filterValue = '';
      this.form.filter[index].showOp = true;
      const possibleOp = this.columns.filter((items) => items.column === this.form.filter[index].filterName);
      this.form.filter[index].dateType = possibleOp[0].dateType;
      this.form.filter[index].filterOpArray = possibleOp[0].possibleOperators;
    },
    saveTemplate() {
      this.loading = true;
      if (!this.isEditingTemplate) {
        const { filter } = this.form;
        const modelFilters = []
        const filterArray = filter.map(obj => {
          modelFilters.push(
            {
              columnName: obj.filterName,
              operator: obj.filterOp,
              dataType: obj.dateType,
              columnValue: obj.value
            }
          )
          const payload = {};

          payload.filterName = obj.filterName;
          payload.showOp = obj.showOp;
          payload.filterOpArray = obj.filterOpArray;
          payload.filterOp = obj.filterOp;
          payload.dateType = obj.dateType;

          if (obj.value1 === '' || obj.value1 === null) {
            payload.fieldValue = [obj.value];
            return payload;
          }
          payload.fieldValue = [obj.value, obj.value1];
          
          return payload;
        });
        const filterArrayString = JSON.stringify(filterArray);

        this.form.templateKey = this.populatedTemplate.templateKey;
        this.form.templateName = this.templateName;
        this.form.templateBody = this.templateBody;
        this.form.templateTarget = this.templateTarget;
        this.form.notificationType = this.notificationType;
        this.form.templateEvent = this.templateEvent;
        const data = { ...this.form, filter: filterArrayString, modelFilters}
        this.updateTemplateForm(data);
      } else {
        this.pushTemplateForm();
      }
    },
    pushTemplateForm() {
      const { filter } = this.form;
      const modelFilters = []
      const filterArray = filter.map(obj => {
        modelFilters.push(
           {
            columnName: obj.filterName,
            operator: obj.filterOp,
            dataType: obj.dateType,
            columnValue: obj.value
          }
        )
        const payload = {};

        payload.filterName = obj.filterName;
        payload.showOp = obj.showOp;
        payload.filterOpArray = obj.filterOpArray;
        payload.filterOp = obj.filterOp;
        payload.dateType = obj.dateType;

        if (obj.value1 === '' || obj.value1 === null) {
          payload.fieldValue = [obj.value];
          return payload;
        }
        payload.fieldValue = [obj.value, obj.value1];
        
        return payload;
      });
      
      const filterArrayString = JSON.stringify(filterArray)
    
      ApiService.post("Template", { ...this.form, filter: filterArrayString, modelFilters })
        .then((resp) => {
          if (resp.data.status !== false) {
            this.loading = false;
            this.$emit("successfully-save-template", resp.data.message);
            this.closeForm();
          } else {
            this.loading = false;
            this.$emit("error-saving-template", resp.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("error-saving-template", error.response.data.message);
        });
    },
    updateTemplateForm(data) {
      ApiService.put("Template", data)
        .then((resp) => {
          if (resp.data.status !== false) {
            this.loading = false;
            this.$emit("successfully-save-template", resp.data.message);
            this.closeForm();
          } else {
            this.loading = false;
            this.$emit("error-saving-template", resp.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("error-saving-template", error.resp.data.message);
        });
    },
    getCustomMenus() {
      GeneralApiService.get(
        "CustomMenu/all-custom-menus"
      ).then((resp) => {
        this.custommenus = resp.data.data;
      });
    },
    closeForm() {
      this.resetForm();
      this.$emit("close");
    },
  },
  mounted() {
    this.getCustomMenus();
  },
};
</script>
<style lang="scss" scoped>
  .col-pad {
    padding-right: 6px;
    padding-left: 6px;
  }
  .modal__content {
    padding: 4.5rem 2.5rem;
  }
  .modal__right .modal__dialog {
    width: 75rem;
  }
</style>
